
<template>
  <div class="jiaoyu_c">
    <div class="jiaoyu_c_title">
      <p>{{ video.title }}</p>
      <p v-html="video.smalltext">| {{ video.label }}</p>
    </div>
    <div class="jiaoyu_c_videobox">
      <div class="videos_div">
        <div id="videos">
          <img
            v-if="videostype"
            class="videosimg"
            :src="video.titlepic"
            alt=""
          />
          <img
            @click="bf"
            class="videosimgbtn"
            v-if="videostype"
            src="https://task.hozoin.cn/Works/20210223/sj-6043917379.png"
            alt=""
          />
        </div>
      </div>
      <div class="jiaoyu_c_videobox_r">
        <div class="jiaoyu_c_videobox_rc">
          <div class="jiaoyu_c_videobox_rctop">
            <img :src="video.userpic" alt="" />
            <span>{{ video.username }}</span>
            <span
              v-if="usergztype"
              @click="gztypeclick"
              :style="gztype == 0 ? bkf : bkc"
              >{{ gztype == "0" ? "关注" : "已关注" }}</span
            >
          </div>
          <div class="jiaoyu_c_videobox_rccenter">
            <div class="jiaoyu_c_videobox_rccenter_t">{{ video.title }}</div>
            <div class="jiaoyu_c_videobox_rccenter_n">
              <div class="jiaoyu_c_videobox_rccenter_ns">
                <icon-svg icon-class="iconlook" />
                <span>{{ video.onclick == null ? "0" : video.onclick }}</span>
              </div>
              <div class="jiaoyu_c_videobox_rccenter_ns">
                <icon-svg icon-class="icongood" />
                <span>{{
                  video.give_like == null ? "0" : video.give_like
                }}</span>
              </div>
              <div class="jiaoyu_c_videobox_rccenter_ns">
                <icon-svg icon-class="iconvedio" />
                <span>{{ count }}</span>
              </div>
            </div>
          </div>
          <div class="jiaoyu_c_videobox_rcbtm" v-html="video.smalltext"></div>
        </div>
      </div>
    </div>
    <zan :all="zanall" />
    <div class="jiaoyu_c_btm">
      <div class="jiaoyu_c_btm_l">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程概述" name="first">
            <div class="jiaoyu_c_text" v-html="video.newstext"></div>
          </el-tab-pane>
          <el-tab-pane label="评论" name="two">
            <Sjpinglun :all="pinglunall" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <ul class="jiaoyu_c_btm_r">
        <li>其他课程</li>
        <li
          class="jiaoyu_ulli"
          v-for="item in jiaoyu_ul"
          :key="item.p_id"
          :class="item.title ? '' : 'Zhanping_bisheullizero'"
          @click="jiaoyu_hejivedio(item.id, item.p_id)"
        >
          <div class="jiaoyu_ulliimg">
            <img :src="item.titlepic" :alt="item.title" />
            <!-- <div class="jiaoyu_ulliimg_type">{{ item.videotype }}</div> -->
          </div>
          <div class="jiaoyu_ullititle">{{ item.title }}</div>
          <div class="jiaoyu_ullicenter">
            <img :src="item.userpic" alt="" />
            <span>{{ item.username }}</span>
            <span>{{ item.money > 0 ? item.money : "免费" }}</span>
          </div>
          <div class="jiaoyu_ullibtm">
            <div class="jiaoyu_ullibtm_rd">
              <icon-svg icon-class="iconlook" />{{ item.onclick }}
            </div>
            <div class="jiaoyu_ullibtm_zan">
              <icon-svg icon-class="icongood" />{{ item.dznum }}
            </div>
            <div>{{ item.create_time }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import zan from "../components/zan";
import Sjpinglun from "@/components/Sjpinglun.vue";
export default {
  components: {
    Sjpinglun,
    zan,
  },
  data() {
    return {
      pinglunall: {
        classid: "7",
        id: "",
      },
      bkc: "background:#ccc",
      bkf: "background:#FF47AF",
      new_videoid: "",
      new_playauth: "",
      videoid: "",
      video: {},
      activeName: "first",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      jiaoyu_ul: [],
      pagesize: 3,
      count: 0,
      videostype: true,
      gztype: "",
      userinfo: {},
      member_id: "",
      zanall: {
        classid: "7",
        id: "0",
      },
      usergztype: false,
    };
  },
  methods: {
    bf() {
      var that = this;
      that.videostype = false;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getPlayVideo",
        method: "post",
        data: {
          video_id: that.videoid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.new_playauth = res.data.result.PlayAuth;
          that.new_videoid = res.data.result.VideoMeta.VideoId;
          var width = Number(document.documentElement.clientWidth);
          if (width > 800) {
            var widthpc = "71.3rem";
            var height = "45.2rem";
          } else {
            var widthpc = "100%";
            var height = "100%";
          }
          var player = new Aliplayer(
            {
              id: "videos",
              vid: that.new_videoid,
              playauth: that.new_playauth,
              cover: res.data.result.VideoMeta.CoverURL,
              width: widthpc,
              height: height,
              autoplay: true,
            },
            function (player) {
              // console.log("播放器创建了");
            }
          );
        } else {
          that.$message({
            message: res.data.message,
            duration: 1000,
          });
        }
      }).catch(err =>  that.$message('播放错误，请联系管理员'));
    },
    gztypeclick() {
      var that = this;
      var gztypett = "";
      if (this.gztype == "1") {
        gztypett = "getCancelFollow";
      } else {
        gztypett = "getFollow";
      }
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/" + gztypett + "",
        method: "post",
        data: {
          userid: that.userinfo.userid,
          member_id: that.member_id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (that.gztype == 0) {
            that.gztype = 1;
            that.$message({
              message: "添加关注成功",
              type: "success",
              duration: 1000,
            });
          } else {
            that.gztype = 0;
            that.$message({
              message: "取消关注成功",
              type: "success",
              duration: 1000,
            });
          }
          this.$forceUpdate();
        } else {
        }
      });
    },
    handleClick() {},
    jiaoyu_hejivedio(id, pid) {
      this.$router.push({
        path: "/Jiaoyu_c",
        query: { data: id, p_id: pid },
      });
      location.reload();
    },
  },
  created() {
    var that = this;
    var id = that.$route.query.data;
    that.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    that.zanall.id = id;
    that.pinglunall.id = id;
    this.$axios({
      url: "/api/getVideoDetail",
      method: "post",
      data: {
        id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.video = res.data.result;
        document.title = res.data.result.title + " 视频详情";
        this.fenxiang(
          document.title,
          res.data.result.title,
         res.data.result.titlepic
        );

        that.videoid = res.data.result.videoid;
        that.member_id = res.data.result.userid;
        if (that.userinfo != null) {
          if (that.userinfo.userid == res.data.result.userid) {
            that.usergztype = false;
          } else {
            that.usergztype = true;
          }
        } else {
          that.usergztype = true;
        }
        ///////
        that
          .$axios({
            url: "/api/getFollowFans",
            method: "post",
            data: {
              userid: that.userinfo.userid,
              f_type: "1",
              page: "1",
              limit: "100000",
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              var fensi = res.data.result.list;
              for (var i = 0; i < fensi.length; i++) {
                if (fensi[i].userid == that.member_id) {
                  that.gztype = "1";
                  return;
                } else {
                  that.gztype = "0";
                }
              }
            } else {
            }
          });
        ////////////
      } else {
        this.$message({
          message: res.data.messages,
          duration: 1000,
        });
      }
    });
    ////
    var p_id = that.$route.query.p_id;
    this.$axios({
      url: "/api/getVideoList",
      method: "post",
      data: {
        p_id,
        page: "1",
        limit: that.pagesize,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.jiaoyu_ul = res.data.result.list;
        that.count = res.data.result.count;
      } else {
      }
    });
  },
};
</script>
<style>
@import "../assets/css/jiaoyu.css";
</style>